@import "/src/app/styles/for-components";

.titleBox{
    width: 90%;
    margin: auto;
    margin-bottom: 2rem;
    text-align: center;
    @include break-up(desktop){
        width: 95%;
        text-align: inherit;
    }
    @include break-up(big-desktop){
        width: 80%;
    }
    h6{
        margin-bottom: 1rem;
    }
    &.left{
        text-align: left;
    }
    &.center{
        text-align: center;
    }
    &.right{
        text-align: right;
    }
}

.buttonBox{
    margin-top:2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    @include break-up(desktop){
        margin-top: 5.25rem;
    }
}

.btnStart{
    justify-content: flex-start ;
}

.btnEnd{
    justify-content: flex-end ;
}

.btnToTop {
    position: fixed;
    display:flex;
    justify-content:center;
    align-items:center;
    z-index: 1;
    right: 8px;
    bottom: 8px;
    width: 50px;
    height: 50px;
    background-color: var(--red);
    transform: translateX(100px);
    opacity: 0;
    visibility: hidden;
    cursor: pointer;
    &:hover{
        background-color: var(--white);
        border: 1px solid var(--primary);
        & svg>path{
            fill: var(--primary);
        }
    }
    & svg{
        width: 50%;
        color: var(--white);
    }
}
