@import "/src/app/styles/for-components";

/*CONTACT FORM*/

.contact-form{
    width: 90%;
    margin: auto;
    @include break-up(desktop){
        display: grid;
        grid: repeat(3,6rem) 3.5rem/repeat(4,1fr);
        column-gap: 1.5rem;
        row-gap: 1.5rem;
        width: 95%;
        padding-bottom: 3.125rem;
        & .input-block:nth-child(1){
            grid-row: 1/1;
            grid-column: 1/2;
        }
        & .input-block:nth-child(2){
            grid-row: 1/1;
            grid-column: 2/3;
        }
        & .input-block:nth-child(3){
            grid-row: 2/3;
            grid-column: 1/3;
        }
        & .input-block:nth-child(4){
            grid-row: 3/4;
            grid-column: 1/3;
        }
        & .input-block:nth-child(5){
            grid-row: 1/1;
            grid-column: 3/5;
        }
        & .input-block:nth-child(6){
            grid-row: 2/4;
            grid-column: 3/5;
        }
    }
    @include break-up(big-desktop){
        width: 90%;
    }
    & .form-button{
        margin: auto;
        margin-top: 3rem;
        @include break-up(desktop){
            grid-row: 4/5;
            grid-column: 4/5;
            margin: 0;
            margin-top: auto;
            width: 80%;
            justify-content: center;
            justify-self: flex-end;
            align-self: center;
        }
    }
    & textarea{
        min-height: 15rem;
        @include break-up(desktop){
            min-height: inherit;
            height: 87%;
            width: 100%;
        }
    }
}

.input-block{
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    @include break-up(desktop){
        margin-bottom: 0;
        & input{
            width: 100%;
        }
    }
    & input{
        width: 100%;
    }
    &.contactType{
        & label{
            display: flex;
            gap: 0.5rem;
            align-items: center;
            margin-right: 2rem;
            font-size: 1rem;
        }
    }
    &.contactType p {
        width: 100%;
        margin-bottom: 1rem;
    }
    & label{
        font-size: 0.8rem;
        margin-bottom: 0.5rem;
    }
    & span:not(.slider) {
        font-size: 0.7rem;
        color: red;
        position: absolute;
        width: max-content;
        bottom: 0;
    }
}

.contactType,
.legalMention{
    display: none;
}

/*ARTICLE-FORM*/

.article-form{
    @include break-up(desktop){
        max-width: 500px;;
        position: sticky;
        top: 20rem;
        display: block;
        width: 50%;
        height: fit-content;
        max-width: 50vw;
        padding: 1em 1.5rem;
        margin-top: 1em;
        border-radius: var(--borderMedium);
        box-shadow: var(--shadow);
        z-index: 5;
        & .input-block{
            flex-flow: row wrap;
        }
        & textarea{
            min-height: 15rem;
        }
    }
    @include break-up(big-screen){
        width: 35%;
    }
    & .contact-form{
        width: 100%;
        margin-top: 2rem;
        grid: repeat(8,auto)/100%;
        padding-bottom: 1rem;
        @include break-up(desktop){
            gap: 0px;
            & .input-block:nth-child(1){
                grid-row: 1/2;
                grid-column: 1/2;
            }
            & .input-block:nth-child(2){
                grid-row: 2/3;
                grid-column: 1/2;
            }
            & .input-block:nth-child(3){
                grid-row: 3/4;
                grid-column: 1/2;
            }
            & .input-block:nth-child(4){
                grid-row: 5/6;
                grid-column: 1/2;
            }
            & .input-block:nth-child(5){
                grid-row: 6/7;
                grid-column: 1/2;
            }
            & .input-block:nth-child(6){
                grid-row: 7/8;
                grid-column: 1/2;
            }
            & .input-block:nth-child(7){
                grid-row: 8/9;
                grid-column: 1/2;
            }
            & .form-button{
                grid-row: 9/10;
                grid-column: 1/2;
                justify-self: center;
        }
    }
    & .subject{
        display: none;
    }
    & .contactType{
        display: block;
    }
    & .input-block.contactType {
        display: flex;
        flex-flow: row wrap;
    }
    & .legalMention{
        font-size: 0.666rem;
        margin-top: 2rem;
        color: var(--red);
        gap: 1rem;
        display: flex;
        flex-flow: column wrap;
        text-align: center;
    }
    }
    & .legalMention{
        @include break-up(desktop){
            grid-row: 10/11;
            grid-column: 1/2;
            justify-self: center;
        }
    }
}
