@import "/src/app/styles/for-components";

.bookingBox {
    --ctl-calendar-font-shorthand: normal normal 400 1.125rem/1.125rem "Open-sans", sans-serif;
    --ctl-calendar-radius: 2rem;
    --ctl-calendar-bg-color: #ffffff;
    --ctl-calendar-bgh-color: rgba(176, 35, 24, 0.2);
    --ctl-calendar-txt-color: #000000;
    --ctl-calendar-txt-active-color: #ffffff;
    --ctl-calendar-txt-inactive-color: #bfbfbf;
    --ctl-calendar-range-color: var(--red);
    --ctl-calendar-range-light-color: rgba(176, 35, 24, 0.5);
    --ctl-calendar-resticted-bg-color: #ffe680;
    --ctl-calendar-booked-bg-color: #f8c7bc;
}

.ct-calendar__controllers__calendarDay.cds-blocked:not(.cds-out-of-range):not(.cds-blocked-booked) {
  text-decoration: none !important;
}

/*BOOKING-BOX*/

.bookingBox{
    border-top: 1px solid var(--borderColor);
    @include break-up(desktop){
        border-top: inherit;
        &  .buttonBox:not(.bookingBox-fixedCalendar .buttonBox, .btnEnd) {
            display: flex;
            margin: 0;
            width: 100%;
        }
        & .btn{
            width: 100%;
            margin-top: 1rem;
        }
    }
    &  form{
        margin-top: 1rem;
    }
    &  .buttonBox{
        display: flex;
        margin: 0;
        margin-bottom: 2rem;
        // &:not(.bookingBox-fixedCalendar .buttonBox) {
        //     display: none;
        // }
    }
}

.bookingBox-title{
    width: 100%;
    background: white;
}

.bookingBox-title-smartphone{
    display: block;
    @include break-up(desktop){
        display: none;
    }
}

p.bookingBox-title-smartphone{
    font-weight: 600;
    font-size: 18px;
    span{
        font-size: 14px;
        font-style: italic;
        font-weight: normal;
        display: block;
    }
}

.bookingBox-inputDate{
    display: none;
    @include break-up(desktop){
        display: flex;
    }
}

.bookingBox-total{
    flex-flow: column wrap;
    font-weight: bold;
    @include break-up(desktop){
        padding-top: 0;
    }
    & p{
        display: flex;
        justify-content: space-between;
        padding: 0.5rem 0;
        @include break-up(desktop){
            padding: 0;
        }
    }
    & .discount{
        color: var(--green);
    }
    & .total{
        border-top: 1px solid var(--dark-grey);
        padding: 0.5rem 0;
        margin-top: 0.5rem;
        font-size: 20px;
        & span{
            font-size: 20px;
        }
    }
}

.bookingBox-inputDate{
    position: relative;
    justify-content: space-between;
    z-index: 10;
    &  .input-block{
        width: 48%;
        &  input{
            padding: 1rem;
            padding-left: 2rem;
        }
        &  svg{
            position: absolute;
            top: 45%;
            left: 5%;
            color: var(--red);
        }
    }

}

/*Fixed calendar*/

.bookingBox-fixedCalendar{
    width: 100vw;
    height: 0px;
    position: fixed;
    bottom: 0px;
    left: 0px;
    background-color: var(--white);
    padding: 0px 5%;
    z-index: 10;
    overflow: scroll;
    @include break-up(desktop){
        display: none;
    }
}

.bookingBox-fixedCalendar-header{
    height: 4.5rem;
    width: 100%;
    position: sticky;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--white);
    z-index: 11;
    &  svg{
        font-size: 1.125rem;
    }
}

.bookingBox-fixedBtn{
    width: 100vw;
    position: fixed;
    bottom: 0px;
    left: 0px;
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content:center;
    padding: 1rem 5%;
    background-color: var(--white);
    box-shadow: var(--shadow);
    z-index: 9;
    gap: 5vw;
    @include break-up(desktop){
        display: none;
    }
    & .bookingBox-fixedBtn-title{
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        width: 100%;
        text-align: center;
        font-weight: bold;
        & p{
            display: flex;
            justify-content: space-between;
        }
        & .discount{
            color: var(--green);
        }
        & .total{
            border-top: 1px solid var(--dark-grey);
            padding: 0.5rem 0;
            margin-top: 0.5rem;
            font-size: 18px;
            & span{
                font-size: 20px;
            }
        }
    }
    & .bookingBox-fixedBtn-subtitle{
        width: auto
    }
    & .bookingBox-fixedBtn-btn{
        display: flex;
        justify-content: flex-end;
    }
    & .btn.small{
        font-size: 1rem;
    }
}

/*CALENDAR*/

.bookingBox-calendar-buttons{
    display: flex;
    align-items: center;
    justify-content: space-between;
    & .btnEnd {
        min-height: 3rem;
        & button{
            display: none;
            @include break-up(desktop){
                display: block;
            }
        }
    }

}

.ct-calendar__controllers__calendarDay.cds-default.cds-valid .ct-calendar__controllers__calendarDayVal{
    border: 1px solid var(--green);
}

.ct-calendar__controllers__calendarWrapper{
    margin: auto;
    margin-top: 3rem;
    @include break-up(desktop){
        margin-top: 0;
    }
}

.ct-calendar__controllers__calendarDayVal,
.ct-calendar__controllers__calendarWeekHeaderVal{
    font-size: 1.125rem;
}

.bookingBox-deleteDate{
    border-bottom: 1px solid var(--black);
    white-space: nowrap;
    cursor: pointer;
    @include break-up(desktop){
        &:hover{
            background-color: var(--light-grey);
        }
    }
    &:active{
        color: var(--red);
        border-color: var(--red);
    }
}

.bookingBox-calendar{
    padding-bottom: 1rem;
    overflow: hidden;
    @include break-up(desktop){
        opacity: 0;
        visibility: hidden;
        justify-content: flex-start;
        position: absolute;
        top: -25%;
        right: 0;
        background-color: var(--white);
        border-radius: var(--borderMedium);
        box-shadow: var(--shadow);
        padding: 0 1rem;
        z-index: 9;
        & h2{
            width: 100%;
        }
        & .bookingBox-calendar-buttons{
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        & .bookingBox-calendar-closeBtn{
            position: absolute;
            top: 1.3rem;
            right: 1rem;
            font-size: 1.5rem;
            cursor: pointer;
        }
        &  .ct-calendar__controllers__calendarWrapper {
            padding-top: 7rem;
        }
    }
    .bookingBox-title-browser{
        display: none;
        @include break-up(desktop){
            display: block;
            align-items: baseline;
            gap: 0.25rem;
            margin: 1rem 0 0rem;
        }
    }
    p{
        font-weight: 600;
        font-size: 18px;
        span{
            font-size: 14px;
            font-style: italic;
            font-weight: normal;
            display: block;
        }
    }
}

.bookingBox-person{
        position: relative;
        border-top: 1px solid var(--borderColor);
        border-bottom: 1px solid var(--borderColor);
        padding: 1rem 0 2rem 0;
        @include break-up(desktop){
            padding: 0;
            border: none;
        }
        &  .input-block{
          display: none;
          width: 100%;
          & input{
            padding: 1rem;
            padding-left: 2rem;
            z-index: 2;
            cursor: pointer;
          }
            @include break-up(desktop){
                display:flex ;
            }
        }
        & h2{
            @include break-up(desktop){
                display: none;
            }
        }
        & .nbrPerson{
            background-color: var(--white);
            width: 100%;
            border-radius: var(--borderMedium);
            max-width: 500px;
            z-index: 1;
            opacity: 1;
            visibility: visible;
            @include break-up(desktop){
                padding: 3rem 1rem 0 1rem;
                position: absolute;
                top: 3rem;
                left: 0;
                box-shadow: var(--shadow);
                opacity: 0;
                visibility: hidden;
            }
        }
        & .buttonBox.btnEnd{
            display: none;
            @include break-up(desktop){
                display: flex;
            }
        }
}

.bookingBox-btn{
    button{
        margin: 2rem auto;
    }
    @include break-up(desktop){
        margin: 0;
    }
}

.bookingBox-person-users{
    position: absolute;
    left: 2%;
    top: 45%;
    color: var(--red);
    z-index: 3;
}

.bookingBox-person-chevron{
    position: absolute;
    top: 45%;
    right: 2%;
    color: var(--red);
    z-index: 3;
    transition: all 0.5s ease-in-out;
    pointer-events: none;
}

.bookingBox-laundry{
    border-top: 1px solid var(--borderColor);
    border-bottom: 1px solid var(--borderColor);
    padding: 2rem 0 1rem 0;
    @include break-up(desktop){
        padding: 0;
        border: none;
    }
    & .prestations{
        width: 100%;
        border: 1px solid var(--dark-grey);
        border-radius: var(--borderSmall);
        & .prestation{
            position: relative;
            display: flex;
            align-items: center;
            width: 100%;
            & p {
                padding: 1rem;
            }
        }
        & .info{
            display:flex;
            align-items:center;
            &:hover>div{
                opacity:1;
                visibility: visible;
            }
            & svg{
                color: var(--red);
            }
            &>div{
                position:absolute;
                top: -50%;
                left: 0;
                background-color: var(--white);
                border: 1px solid var(--red);
                border-radius: var(--borderSmall);
                opacity: 0;
                visibility: hidden;
                z-index: 10;
            }
        }
    }
    & .input-block{
        width: 100%;
        &  input{
            padding: 1rem;
            padding-left: 2rem;
        }
    }
    & .switch{
        position:absolute;
        top: 50%;
        right: 5%;
        transform: translateY(-50%);
    }
    &  svg:not(.info>svg){
        position: absolute;
        top: 45%;
        left: 2%;
        color: var(--red);
    }
}


