@import "/src/app/styles/for-components";

.subInput{
    display: grid;
    position: absolute;
    top: 126%;
    left: 50%;
    transform: translateX(-50%);
    grid-template-columns: 1fr;
    grid-template-rows: 3fr;
    padding: 1.302rem;
    background-color: var(--white);
    box-shadow: var(--shadow);
    border-radius: var(--borderSmall);
    border:1px solid var(--borderColor);
    color: var(--black);
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s ease-in-out;
    cursor: default;
    @include break-down(big-desktop){
        &.type{
            left: 0;
            transform: inherit;
            .subInput-arrow,.subInput-hideArrow{
                left: 10%;
            }
        }
    }
    & label,p{
        font-size:0.888em; /*16px*/
        white-space: nowrap;
        display: flex;
        align-items: center;
        gap: 0.4rem;
        cursor: pointer;
    }
}

.subInput-title{
    white-space: nowrap ;
    span{
        font-weight: normal;
        display: block;
        font-style: italic;
    }
}

.subInput-arrow{
    position: absolute;
    top: -0.6rem;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
    width: 1.302rem;
    height: 1.302rem;
    background-color: var(--white);
    box-shadow: var(--shadow);
    border-radius: 4px;
    border:1px solid var(--borderColor);
    z-index: 1;
}

.subInput-hideArrow{
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 4rem;
    height: 1.302rem;
    z-index: 2;
    background-color: var(--white);
}

.subInput-inputs,.subInput-validation{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.subInput-inputs{
    padding: 1.302rem 0 1.651rem 0 ;
    border-bottom: 1px solid var(--borderColor);
    &:has(#weekly) {
        flex-flow: row nowrap;
        gap: 1rem;
    }
}

.subInput-validation{
    padding-top: 0.621rem;
}

.articlePrice{
    min-width: 3rem;
}

.articlePrice-container{
    display: flex;
    flex-flow: row wrap;
}

.nbrPerson{
    display: flex;
    flex-flow: row wrap;
    min-width: 13rem;
    gap: 2rem;
    & p{
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-items: flex-start;
        font-size: 20px;
        @include break-up(desktop){
            font-size: initial;
        }
        &  span{
            width: 100%;
            font-size: 14px;
            line-height: 1;
            opacity: 0.7;
            text-transform: lowercase;
            @include break-up(desktop){
                font-size: 12px;
            }
        }
    }
    & .counter{
        display: flex;
        align-items: center;
        gap: 0.5rem;
        & span{
            font-size: 22px;
            @include break-up(desktop){
                font-size: initial;
            }
        }
    }
    & .counter button{
        width: 3.813rem;
        height: 2.875rem;
        border: 1px solid var(--borderColor);
        border-radius: var(--borderMedium);
        font-size: 22px;
        @include break-up(desktop){
            width: 2.813rem;
            height: 1.875rem;
            font-size: initial;
        }
    }
    & button:not(.nbrPerson button:disabled):hover{
        border-color: var(--red);
    }
}

.nbrPerson-block{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    & p{
        text-transform: capitalize;
    }
}

