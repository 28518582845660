@import "/src/app/styles/for-components";

footer:not(.calendar footer){
    position: relative;
    width: 100%;
    background-color:var(--blue);
    color: var(--white);
    padding-top: 2rem;
    @include break-up(desktop){
        padding-top:12.222rem;
    }
}

.footer-callToAction{
    display: none;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;      
    justify-content: space-between;       
    width: max-content;
    position: absolute;
    top: -5rem;
    left: 50%;
    padding: 3vw 4vw;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
            transform: translateX(-50%);
    color: var(--white);
    background-color: var(--red);
    border-radius: var(--borderMedium);
    @include break-up(desktop){
        display: block;
    }
    div{
        display: inline-block;
        width: auto;
        vertical-align: middle;
    }
    div:nth-child(1){
        margin-right: 4rem;
    }
    div,p{
        padding: 0;
        margin: 0;
    }
    & h3{
        width: 100%;
        margin-bottom: 1rem;
    }
    & .buttonBox{
        margin: 0;
    }
}

.footer-nav{
    display:grid;
    grid: repeat(6,auto)/100%;
    width: 80%;
    margin: auto;
    gap: 1rem;
    @include break-up(desktop){
        grid: auto / repeat(6,auto);
    }
}

.footer-list{
    &>p{
        margin: 1rem 0 0.5rem 0;
        font-weight: bold;
        font-size:1rem ;
        letter-spacing: 1.2px;
        text-transform: uppercase;
    }
    & li{
        margin: 0.5rem 0;
        @include break-up(desktop){
            margin: 1.5rem 0;
            font-size: 16px;
            cursor: pointer;
            &:hover{
                color: var(--red);
            }
        }
    }
}

.footer-contactInfo{
    font-style: initial;
    @include break-up(desktop){
        grid-row: 1/1;
        grid-column: 1/2;
    }
    & div,a{
        display: flex;
        padding: 0.5rem 0;
        gap: 0.5rem;
        font-size: 16px;
    }
    & a:hover:not(.footer-icones a){
        color: var(--red);
    }
}

div.footer-logo{
    display: none;
    @include break-up(desktop){
        display: block;
        margin-bottom: 2rem;
        & svg{
            fill: var(--white);
        }
    }
}

.footer-icones{
    justify-content: space-evenly;
    margin: 2rem 0;
    @include break-up(desktop){
        justify-content: space-between;
        width: 100%;
        cursor: pointer;
    }
    @include break-up(big-desktop){
        width: 65%;
    }
    & a{
        align-items: center;
        justify-content: center;
        display: flex;
        border-radius: 100px;
        padding: 0.5rem;
        border:1px solid var(--white) ;
        height: 2.5rem;
        width: 2.5rem;
        color: var(--white);
        &:active{
            background-color: var(--red);
            border-color: var(--red);
        }
        @include break-up(desktop){
            &:hover{
                background-color: var(--red);
                border-color: var(--red);
            }
        }
    }
}

.copyright{
    border-top: 1px solid var(--white);
    font-size: 0.9rem;
    padding: 1rem 0;
    line-height: 1.8;
    text-align: center;
    @include break-up(desktop){
        width: 80%;
        margin: auto;
        & .copyright-break{
            display: none;
        }
    }
    & a{
        font-size: 0.68rem;
        &:hover{
            color: var(--red);
        }
    }

}


