@import "react-loading-skeleton/dist/skeleton.css";
@import "react-day-picker/dist/style.css";
@import "@splidejs/splide/dist/css/splide.min.css";
@import "@ct-react/calendar/dist/ct-react.calendar.css";
@import "fonts";
@import "for-components";

:root{
    --red:#B02318;
    --light-red:#EED2D0;
    --green:#0DBC72;
    --black:#0D152F;
    --white:#FFF;
    --blue:#0D152F;
    --light-grey:#F9F9F9;
    --dark-grey:#707070;
    --yellow:#FDFADB;
    --shadow: rgb(14 21 45 / 23%) 0px 0px 10px;
    --borderSmall:0.625rem;
    --borderMedium:1.302rem;
    --borderBig:2.865rem;
    --borderColor:#DDD;
    --ctl-calendar-range-light-color:rgba(176, 35, 24, 0.5);
    --ctl-calendar-range-color:#B02318;
}

*{
    outline: 0;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
    -webkit-tap-highlight-color:rgba(0, 0, 0, 0);
    @include break-down(smallMobile){
        font-size: 14px;
    }
}

html{
    scroll-behavior: smooth;
    color: var(--black);
    font-size: 16px;
}

body{
    max-width: 1920px;
    margin: auto;
}


ul,li{
    list-style: none;
    font-size: 1em;
}

a{
    font-size: 1rem;
    color: inherit;
    text-decoration: inherit;
    cursor: pointer;
}

*.disabled-link, *:disabled{
    pointer-events: none;
    cursor: not-allowed;
}

*[data-gloss]{
    position: relative;
    &:hover:after{
        background: var(--blue);
        font-size:0.611rem ;
        border-radius: 5px;
        bottom: -34px;
        color: var(--white);
        content: attr(data-gloss);
        left: 50%;
        transform: translateX(-50%);
        padding: 6px 15px;
        position: absolute;
        z-index: 999;
        width: auto;
        white-space: nowrap;
    }
    &:hover:before {
        border: solid;
        border-color: var(--blue) transparent;
        border-width: 0 6px 6px 6px;
        bottom: -9px;
        content: "";
        left: 50%;
        transform: translateX(-50%);
        position: absolute;
        z-index: 999;
    }
}

button{
    border: inherit;
    background-color: transparent;
    -webkit-appearance: none;
}

label{
    position: relative;
    color: var(--black);
    font-size: 1rem;
    @include break-up(desktop){
        font-size:0.729em;
    }
}

::placeholder{
    font-size: 0.888rem;
}

::-moz-selection {
    color: var(--white);
    background: var(--red);
}

::selection {
    color: var(--white);
    background: var(--red);
}

/*---SCROLL BAR---*/

::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    background-color: var(--red);
  }

/*UTILS*/

.hide{
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s ease-in-out;
}

.show{
    opacity: 1;
    visibility: visible;
    transition: all 0.5s ease-in-out;
}

.columnWrap{
    flex-flow: column wrap;
}

.rowWrap{
    flex-flow: row wrap;
}

.bottom100{
    margin-bottom: 6.25rem;
}

/*___TEXT STYLING___*/

h1{/*Title*/
    font-family: SangBleuKingdom,'Arial', 'sans-serif';
    font-size:2.3rem; /*28px*/
    text-align: center;
    color: var(--white);
    @include break-up(desktop){
        font-size:3.888em; /*70px*/
        text-align: center;
        color: var(--white);
    }
}

h2{/*Section title*/
    font-family: SangBleuKingdom,'Arial', 'sans-serif';
    font-size:1.437rem; /*23px*/
    @include break-up(desktop){
        font-size:2.5em; /*45px*/
    }
}

h3{/*Call to action*/
    font-family: SangBleuKingdom,'Arial', 'sans-serif';
    font-size:1.666rem; /*30px for browser*/
}

h4{/*Carousel about title*/
    font-size:1.312rem;
    @include break-up(desktop){
        font-size:1.111rem;
    }
}

h5{/*Input title & card title*/
    font-family: SangBleuKingdom,'Arial', 'sans-serif';
    font-size: 1.325rem;
    font-weight: bold;
}

h6{/*Section subtitle*/
    font-size:0.812rem;
    text-transform: uppercase;
    letter-spacing: 1.1px;
    @include break-up(desktop){
        font-size:0.888em;
    }
}

p{
    line-height: 1.7;
    font-size: 1rem;
}

.bold-red,.bold-black,.bold-white{
    font-weight: bold;
}

.semi-bold{
    font-weight: 400;
}

.bold-black{
    color: var(--black);
}

.bold-red{
    color: var(--red);
}

.bold-white{
    color: var(--white);
}

/*___COLOR___*/

.primary{
    background-color: var(--red);
    color: var(--white);
    fill: var(--white);
}

.secondary{
    background-color: var(--blue);
    color: var(--white);
}

.black{
    background-color: var(--black);
    color: var(--white);
    fill: var(--white);
}

.white{
    background-color: var(--white);
    color: var(--black);
    fill: var(--black);
}

.green{
    background-color: var(--green);
    color: var(--white);
    fill: var(--white);
}

.primaryText{
    color:var(--red);
    fill:var(--red)
}

.secondaryText{
    color:var(--blue);
    fill:var(--blue)
}

.whiteText{
    color:var(--white);
    fill:var(--white)
}

.blackText{
    color:var(--black);
    fill:var(--black)
}

.darkGreyText{
    color: var(--dark-grey);
}

/*LAYOUT*/

#root{
    width: 100%;
}

.mainHeader{
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 10;
    @include break-up(desktop){
        position: static;
        width: 100%;
        z-index: 999;
    }
}

/*TOGGLE BUTTON*/


.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    & input{
        opacity: 0;
        width: 0;
        height: 0;
    }
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--borderColor);
    -webkit-transition: .4s;
    transition: .4s;
    &:before{
        position: absolute;
        content: "";
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
    }
}

.toggle-btn{
    &:checked + .slider{
        background-color: var(--red);
    }
    &:focus + .slider{
        box-shadow: 0 0 1px var(--red);
    }
    &:checked + .slider:before{
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
    }
}

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
    &:before{
        border-radius: 50%;
    }
}

/*Commun*/

input[type="text"],input[type="tel"],input[type="email"],select,textarea{
    border: 1px solid var(--dark-grey);
    border-radius: var(--borderSmall);
    padding: 1rem 1rem;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    &:focus{
        border-color: #4671ff;
    }
}

select {
    cursor: pointer;
    background: url("/src/app/assets/arrow.svg") 96% / 3% no-repeat;
}

/*Number*/
input[type="number"]{
    -webkit-appearance: none;
    appearance: none;
    min-width: 7.810rem;
    border: 1px solid var(--borderColor);
    border-radius: var(--borderSmall);
    padding: 0.625rem;
    padding-right:2.8rem ;
    &:focus{
        border-color: var(--red);
    }
    &::-webkit-inner-spin-button,::-webkit-outer-spin-button{
        -webkit-appearance: none;
    }
}

/*Checkbox*/
input[type="checkbox"]:not(.toggle-btn) {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
    font: inherit;
    color: var(--red);
    width: 18px;
    height: 18px;
    border: 1px solid var(--red);
    border-radius: 0.208rem;
    display: grid;
    place-content: center;
    cursor: pointer;
    &:before{
        content: "";
        width: 12px;
        height: 12px;
        clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
        transform: scale(0);
        transform-origin: bottom left;
        transition: 120ms transform ease-in-out;
        box-shadow: inset 1rem 1rem var(--form-control-color);
        /* Windows High Contrast Mode */
        background-color: var(--red);
        @include break-up(desktop){
            width: 0.521rem;
            height: 0.521rem;
        }
    }
    &:checked::before{
        transform: scale(0.9);
    }
    &.read-only{
        border-color: var(--dark-grey);
        &::before{
            background-color: var(--dark-grey);
        }
    }
    @include break-up(desktop){
        width: 0.885rem;
        height: 0.885rem;
    }
}

/*Radio*/
input[type="radio"] {
    -webkit-appearance: none;
    appearance: none;
    background-color: var(--form-background);
    margin: 0;
    font: inherit;
    color: var(--red);
    width: 18px;
    height: 18px;
    border: 1px solid var(--red);
    border-radius: 50%;
    display: grid;
    place-content: center;
    cursor: pointer;
    @include break-up(desktop){
        width: 0.885rem;
        height: 0.885rem;
    }
    &:before{
        content: "";
        width: 10px;
        height: 10px;
        border-radius: 50%;
        transform: scale(0);
        transition: 120ms transform ease-in-out;
        box-shadow: var(--shadow);
        background-color: var(--red);
        @include break-up(desktop){
            width: 0.46rem;
            height: 0.45rem;
        }
    }
    &:checked::before{
        transform: scale(0.9);
    }
}

/*Range*/
input[type="range"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
    outline: none;
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    background-color: transparent;
    pointer-events: none;
    &::-webkit-slider-runnable-track{
        -webkit-appearance: none;
        height: 0.521em;
    }
    &::-moz-range-track{
        -moz-appearance: none;
        height: 0.521em;
    }
    &::-ms-track{
        appearance: none;
        height: 0.521em;
    }
    &::-webkit-slider-thumb{
        -webkit-appearance: none;
        height: 1em;
        width: 1em;
        background-color: #ffffff;
        border: 3px solid var(--red);
        cursor: pointer;
        margin-top: -0.25em;
        pointer-events: auto;
        border-radius: 50%;
    }
    &::-moz-range-thumb{
        -webkit-appearance: none;
        height: 1em;
        width: 1em;
        cursor: pointer;
        border-radius: 50%;
        background-color: #ffffff;
        border: 3px solid var(--red);
        pointer-events: auto;
        border: none;
    }
    &::-ms-thumb{
        appearance: none;
        height: 1em;
        width: 1em;
        cursor: pointer;
        border-radius: 50%;
        background-color: #ffffff;
        border: 3px solid var(--red);
        pointer-events: auto;
    }
    &:active::-webkit-slider-thumb{
        transform: scale(1.5);
    }
}

.rangeLabel{
    min-width: 95%;
    margin: 2rem auto 1rem auto;
    @include break-up(desktop){
        min-width: 300px;
        margin: 1rem auto 2rem auto;
    }
    & span{
        position: absolute;
        top: -2rem;
        right: 5%;
    }
}

.inputRangeColor{
    position: absolute;
    left: 0;
    width: 100%;
    min-width: 15.625rem;
    height: 0.521rem;
    border-radius: 50px;
}

/*Choice of room nbr*/

label.roomLabel{
    position: relative;
    min-width: 2.604rem;
    height: 2.604rem;
    cursor: pointer;
    & *{
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
    }
    &:first-of-type{
        width: 4rem;
    }
}

input[type="radio"].roomCheckbox{
    width: 100%;
    height: 100%;
    border-radius: 0.625rem;
    border-color: var(--borderColor);
    &:hover{
        border-color: var(--red);
    }
    &:before{
        content: "";
        position: absolute;
        width: 0%;
        height: 0%;
        transition: 120ms all ease-in-out;
        border-radius: 0.625rem;
        background-color: var(--red);
        clip-path: none;
        transform: none;
        transform-origin: none;
        z-index: 0;
    }
    &:checked::before{
        width: 110%;
        height: 110%;
    }
    &:checked ~ span{
        color: var(--white);
    }
}

/*RESET*/

input[type="reset"]{
    outline: 0;
    background: none;
    border: none;
    text-decoration: underline;
    font-weight: 400;
    color: var(--dark-grey);
    &:active{
        columns: var(--red);
    }
}

