@import "/src/app/styles/for-components";

.contact-block{
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    margin-bottom: 4rem;
    @include break-up(big-desktop){
        margin-bottom: 0;
    }
    & h5{
        margin: 2rem 0;
    }
    & p, a {
        margin-bottom: 0.5rem;
        white-space: nowrap;
    }
}

.contact-icone{
    width: 4.72rem;
    height: 4.72rem;
    background-color: var(--red);
    border-radius: var(--borderMedium);
    display:flex;
    justify-content:center;
    align-items:center;
}

.contact-firstSpan{
    display: none;
}

.contact-secondSpan{
    display: block;
}

.contactType{
    display: none;
}

.section-form{
    @include break-up(desktop){
        width: 95%;
    }
    @include break-up(big-desktop){
        width: 60vw;
        & .titleBox{
            width: 90%;
        }
    }
}
