@import "/src/app/styles/for-components";

.link-list{
    width: 95%;
    margin: auto;
    margin-bottom: 10rem;
    padding-left: 1.2rem;
    @include break-up(desktop){
        width: 80%;
    }
    li{
        list-style: disc;
        &::marker{
            color: var(--red);
        }
    }
    a{
        display: block;
        margin: 1rem 0;
        font-weight: 700;
        &:hover{
            color: var(--red);
            text-decoration: underline;
        }
        &:visited{
            color: var(--green);
            text-decoration: underline;
        }
        
    }
}