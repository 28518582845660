@import "/src/app/styles/for-components";

.notFound{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3rem;
    width: 100%;
    min-height: 92vh;
    background-repeat: no-repeat;
    background-size: cover;
    backdrop-filter: grayscale(30%);
    @include break-up(desktop){
        min-height: inherit;
        padding-bottom: 14vw;
    }
    & *:not(.blackVeil){
        z-index: 1;
    }
}

.error{
    display: flex;
    flex-flow: column wrap;
    width: 100%;
    text-align: center;
    font-size: 7rem;
    color: var(--white);
    @include break-up(desktop){
        font-size: 12rem;
    }
}

.errorText{
    width: 100%;
    text-align: center;
    color: var(--white);
    font-size: 2rem;
}

.errorText2{
    width: 100%;
    text-align: center;
    color: var(--white);
    font-size: 1rem;
    @include break-up(desktop){
        font-size: 1.5rem;
    }
}

@media only screen and (min-width: 992px) {
    .error{
        font-size: 15rem;
    }
}
