@import "/src/app/styles/for-components";

.cart-item{
    width: 100%;
    margin: auto;
    position: relative;
    display: flex;
    flex-flow: column wrap;
    padding: 2rem 1rem;
    margin-bottom: 5rem;
    box-shadow: var(--shadow);
    border-radius: var(--borderSmall);
    overflow: hidden;
    @include break-up(desktop){
        border-radius: var(--borderMedium);
        padding: 2rem;
        flex-flow: row wrap;
        justify-content: space-between;
        gap: 1rem;
    }
    @include break-up(big-desktop){
        gap: 0;
    }
    & .item-img{
        width: 100%;
        contain: paint;
        flex: 1 1 35%;
        @include break-up(big-desktop){
            max-height: 258px;
            max-width: 350px;
        }
    }
    & .item-title, .item-date{
        width: 100%;
    }
    & .item-date{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-flow: row wrap;
        gap: 1rem;
        margin-top: 2rem;
        border-top: 1px dashed var(--borderColor);
        padding-top: 1rem;
        @include break-up(desktop){
            flex-flow: row nowrap;
            justify-content: space-between;
        }
        & svg{
            color: var(--red);
            height: 20px;
        }
        & span{
            margin: 0 0.5rem;
            white-space: nowrap;
        }
        & p{
            width: auto;
            text-align: center;
            @include break-up(desktop){
                flex-flow: row nowrap;
                text-align: initial;
            }
        }
        & button{
            width: 100%;
            @include break-up(desktop){
                width: initial;
            }
        }
    }
    & .item-price{
        margin-top: 2rem;
        width: 100%;
        @include break-up(big-desktop){
            margin-top: 0;
            padding: 0 2rem;
            width: auto;
            min-width: 400px;
        }
        & h5{
            border-bottom: 1px solid var(--borderColor);
            padding-bottom: 0.5rem;
            margin-bottom: 0.5rem;
        }
        &>div{
            display:flex;
            justify-content:space-between;
            align-items:center;
            padding: 0.25rem 0;
            & span{
                font-weight: 500;
            }
            &.total{
                border-top: 1px solid var(--borderColor);
                margin-top: 0.5rem;
                padding-top: 0.5rem ;
                & p, span{
                    font-size: 18px;
                    font-weight: 500;
                }
            }
        }
        & .discount{
            color: var(--green);
        }
        .cart-price-account{
            p, span{
                font-weight: bold;
                font-size: 18px;
            }
        }
    }
    & img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: var(--borderSmall);
        @include break-up(desktop){
            border-radius: var(--borderMedium);
        }
    }
    & h3{
        padding-bottom: 2rem;
    }
}

.skeleton{
    & .item-title{
        margin-bottom: 1rem;
    }
    & .item-img-skeleton{
        width: 86vw;
        height: 80vw;
        background-color: var(--light-grey);
        border-radius: var(--borderMedium);
        overflow: hidden;
        @include break-up(desktop){
            width: 150px;
            height: 150px;
        }
    }
}


