@import "/src/app/styles/for-components";

.blog {
  display: flex;
  flex-flow: column wrap;
  width: 90%;
  margin: 3rem auto;
  gap: 2rem;
  @include break-up(desktop) {
    width: 60%;
    flex-flow: row wrap;
    margin: 5rem auto 8rem;
  }
}

.post {
  width: 100%;
  @include break-up(desktop) {
    margin: 0 auto 8rem;
  }
}

.post .post-img {
  width: 100%;
  height: 250px;
  background-color: #ebebeb;
  @include break-up(desktop) {
    height: 450px;
  }
  & img {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
  }
}

.post .post-label {
  display: flex;
  gap: 1rem;
  align-items: center;
  padding: 2rem 0;
  border-top: 1px solid var(--red);
  border-bottom: 1px solid var(--red);
  color: var(--red);
}

.post .post-img ~ .post-label { border-top: 0; }

.post h1 {
  margin: 2rem 0 1rem;
  text-align: left;
  line-height: 1.25;
  font-size: 2.25rem !important;
  color: var(--black);
}

.post .post-lead { margin: 1rem 0 2rem; }

// html import formatting

.format-html {

  div, p, span, a, li, dt, dd, strong, em, i, u, b, blockquote, code, pre  {
    background-color: transparent !important;
    font-family: "Open Sans", "Helvetica Neue", sans-serif !important;
    font-size: 1rem !important;
    line-height: 1.7rem !important;
    font-weight: 400 !important;
    color: var(--black) !important;
    text-align: left;
  }

  strong, b, strong > *, b > * {
    font-family: 'SangBleuKingdom', "Arial", sans-serif !important;
    font-weight: 600 !important;
  }

  h2 {
    margin: 1.75rem 0 1rem;
    font-size: 1.5rem;
  }

  h3 {
    margin: 1.375rem 0 .75rem;
    font-size: 1.375rem;
  }

  p ~ br, p > br:first-child, p > br:last-child { display: none; }

  p, ul, ol, blockquote {
    margin: 1rem 0 1rem 0;
    &:first-child { margin-top: 0; }
    &:last-child { margin-bottom: 0; }
  }

  ul {
    padding-inline-start: 1.5rem;
    &, li { list-style: disc; }
  }

  ol {
    padding-inline-start: 1.5rem;
    &, li { list-style: decimal; }
  }

  a {
    font-weight: 600 !important;
    text-decoration: underline !important;
    color: var(--red) !important;
    &:hover { color: var(--red) !important; }
  }

  blockquote {
    position: relative;
    display: inline-block;
    border-left: 6px solid var(--light-red);
    background: var(--light-grey) !important;
    padding: .75rem 1.5rem .75rem 2.75rem;
    font-style: italic;
    line-height: 1.25rem !important;

    p {
      margin: 0;
      line-height: 1.25rem !important;
      & ~ &  { margin-top: .325rem; }
    }

    &::before {
      content: "\201C";
      position: absolute;
      left: .5rem;
      top: 1rem;
      font-size: 3rem;
      font-family: "Arial", serif;
      color: var(--light-red);
    }
  }

  pre { text-wrap: unset; }
  hr { display: none !important; }

}
