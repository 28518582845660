@import "/src/app/styles/for-components";

.connexion-choice{
    display: flex;
    flex-flow: column wrap;
    width: 90%;
    margin: auto;
    @include break-up(desktop){
        width: 80%;
        flex-flow: row nowrap;
        justify-content: space-between;
        padding: 2rem;
        box-shadow: var(--shadow);
        border-radius: var(--borderMedium);
    }
    &>div{
        width: 100%;
        padding: 2rem 0;
        border-bottom: 1px solid var(--borderColor);
        @include break-up(desktop){
            width: 45%;
            padding: 0;
            border-bottom: none;
        }
    }
    & dl{
        display: grid;
        grid-template-rows: repeat(4, auto);
        @include break-up(desktop){
            grid-template-rows: repeat(4,1fr);
        }
    }
    & dt{
        align-self: center;
        @include break-up(desktop){
            margin-top: 2rem;
        }
    }
    & dd{
        margin: 1rem 0;
    }
    & h5{
        margin-bottom: 2rem;
        @include break-up(desktop){
            margin-bottom: 0;
        }
    }
    & button{
        width: 100%;
        margin: 1rem 0;
        @include break-up(desktop){
            margin: 0;
        }
    }
}
