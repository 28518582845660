@import "/src/app/styles/for-components";

.connexion-form{
    display: flex;
    flex-direction: column;
    margin-bottom: 5rem;
    @include break-up(desktop){
        flex-flow: row wrap;
        justify-content: space-between;
        margin-bottom: 0;
        width: 95%;
        min-width: 800px;
        margin: auto;
    }
    @include break-up(big-desktop){
        width: 70%;
    }
    &>div{
        width: 100%;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        @include break-up(desktop){
            width: 45%;
        }
    }
    & .input-block{
        width: 100%;
        &.street{
            width: 70%;
            @include break-up(desktop){
                width: 75%;
            }
        }
        &.streetNbr{
            width: 25%;
            @include break-up(desktop){
                width: 15%;
            }
        }
        &.npa{
            width: 20%;
        }
        &.city{
            width: 70%;
        }
    }
    & button{
        width: 100%;
        margin: 1rem 0;
        @include break-up(desktop){
            width: 45%;
            margin: 2rem 0;
        }
    }
}
