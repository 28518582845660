@import "/src/app/styles/for-components";

.article-map{
    width: 100%;
    margin: auto;
    @include break-up(desktop){
        width: 95%;
        border-radius: var(--borderMedium);
        overflow: hidden;
        margin-bottom: 6.25rem;
    }
    @include break-up(big-desktop){
        width: 80%;
    }
}

.leaflet-popup-content{
    min-width: 210px;
    @include break-up(desktop){
        min-width: 300px;
    }
}
