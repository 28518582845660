@import "/src/app/styles/for-components";

.payment-result{
    width: 90%;
    margin: auto;
    display: flex;
    align-items: center;
    flex-flow: column wrap;
    padding: 2rem 1rem;
    box-shadow: var(--shadow);
    border-radius: var(--borderMedium);
    margin-bottom: 5rem;
    text-align: center;
    @include break-up(desktop){
        width: 50%;
        padding: 2rem;
        margin-bottom: 0;
    }
    &>div:first-child{
        display:flex;
        justify-content:center;
        align-items:center;
        width: 100px;
        height: 100px;
        border-radius: 200px;
        border: 4px solid var(--red);
        color: var(--red);
        margin-bottom: 2rem;
        &.succeeded{
            border: 4px solid var(--green);
            color: var(--green);
        }
        & svg{
            height: 70%;
        }

    }
    & p{
        margin-top: 2rem;
    }
    & button{
        margin-top: 2rem;
    }
}
