@import "/src/app/styles/for-components";

.managment{
    @include break-up(desktop){
        margin-bottom: 100px;
    }
}

ul.managment-list {
    padding-left: 1rem;
    & li::marker {
        color: var(--red);
    }
    & li{
        list-style: disc;
        margin-bottom: 0.5rem;
    }
}



