@import "/src/app/styles/for-components";

.articlesCarousel{
    width: 90%;
    margin: auto;
    position: relative;
    @include break-up(desktop){
        width: 85%;
        margin: auto;
        padding: 0;
        max-height: 31rem;
    }
    &>.splide__track{
        height: fit-content;
        overflow: visible;
        @include break-up(desktop){
            overflow: hidden;
        }
    }
    & .splide__list{
        height: auto;
    }
}

button.splide__pagination__page.is-active{
     background-color: var(--red);
}

/*RESPONSIVE CAROUSEL*/

.carouselResponsive{
    margin: auto;
    width: 80%;
    padding-bottom: 4rem;
    @include break-up(desktop){
        width: 95%;
        margin: auto;
        padding: 0;
        max-height: 31rem;
        padding-bottom: 2rem;
    }
    @include break-up(big-desktop){
        width: 80%;
    }
    & .splide__list{
        height: auto;
    }
}

/*NON-RESPONSIVE CAROUSEL*/

.carousel{
    @include break-up(desktop){
        width: 95%;
        margin: auto;
        padding: 0;
        max-height: 31rem;
        & .splide__slide{
            padding: 1rem 0;
        }
    }
    @include break-up(big-desktop){
        width: 88%;
    }
    & .splide__list{
        height: auto;
    }
}

.carousel-arrowsBlock {
    position: absolute;
    bottom: 2rem;
    left: 50%;
    width: 10rem;
    margin: auto;
    transform: translateX(-50%);
    @include break-up(desktop){
        bottom: inherit;
        top: -6rem;
        left: inherit;
        transform: inherit;
        right: 0;
        height: 4rem;
        width: 12rem;
    }
}

.splide__arrow.carousel-arrow{
    background-color: transparent;
    width: 4rem;
    height: 3rem;
    border-radius: 100px;
    @include break-up(desktop){
        background-color: inherit;
        width: 4.37rem;
        height: 75%;
        border-radius: 150px;
        &:hover{
            border: 1px solid var(--red);
            & svg{
                fill: var(--red);
            }
        }
    }
    &:active{
        background-color: var(--red);
        & svg{
            fill: var(--white);
        }
    }
    &  svg{
        width: 2rem;
        height: auto;
        @include break-up(desktop){
            height: 2rem;
        }
    }
    svg,path{
        pointer-events: none;
    }
}

.splide__pagination.carousel-pagination{
    bottom: -2em;
    @include break-up(desktop){
        bottom: -2.5rem;
    }
}

.article-image-carousel{
    @include break-up(desktop){
        width: 70vw;
    }
    & ul{
        width: 100%;
    }
    .splide__slide{
        height: 40vw;
    }
}

.article-image-carousel-current{
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    padding: 0.1rem 0.5rem;
    font-size: 12px;
    color: var(--white);
    background-color: rgba(0,0,0,0.5);
    border-radius: 4px;
    z-index: 1;
}

.splide.is-initialized.carousel:not(.is-active) .splide__list,
.carousel .splide__list{
    @include break-up(desktop){
        display: grid;
        grid: repeat(2,auto)/repeat(3, calc((100% - 4rem) / 3));
        gap: 2rem;
    }
}


