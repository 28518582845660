@import "/src/app/styles/for-components";

.aboutSection-list{
    display:none
}

@include break-up(desktop){
    .navigation.hide{
        visibility: visible;
        opacity: 1;
}

    .aboutSection-list{
        display:flex;
        flex-flow:row wrap;
        justify-content:space-between;
        margin-top:1rem;
        & li{
            width:48%;
            display:flex;
            align-items: center;
            gap: 1rem;
            margin-bottom: 1rem;
            font-size:0.777rem;
        }
    }

    .aboutSection-list-marker{
        color:var(--white);
        padding: 0.3rem;
        & div{
            display:flex;
            justify-content:center;
            align-items:center;
            height: 30px;
            width: 30px;
            border-radius:100px;
            background: var(--red);
        }
    }

    .blog-section{
        padding-bottom: 12.25rem;
    }

    .home-about{
        margin-bottom: 5rem;
    }
}
