@import "/src/app/styles/for-components";

.loader{
    position: fixed;
    display:flex;
    justify-content:center;
    align-items:center;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 1000;
    & svg {
        width: 3.25em;
        transform-origin: center;
        animation: rotate4 2s linear infinite;
       }
    & circle {
        fill: none;
        stroke: var(--red);
        stroke-width: 2;
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
        stroke-linecap: round;
        animation: dash4 1.5s ease-in-out infinite;
       }
}



   @keyframes rotate4 {
    100% {
     transform: rotate(360deg);
    }
   }

   @keyframes dash4 {
    0% {
     stroke-dasharray: 1, 200;
     stroke-dashoffset: 0;
    }

    50% {
     stroke-dasharray: 90, 200;
     stroke-dashoffset: -35px;
    }

    100% {
     stroke-dashoffset: -125px;
    }
   }
