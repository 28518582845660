@import "/src/app/styles/for-components";

.btn{
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    border: none;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 100px;
    border: 1px solid transparent;
    padding: 1rem 2rem;
    border: 1px solid transparent;
    text-wrap: nowrap;
    & a{
        width: 100%;
        height: 100%;
    }
    &[type="submit"]{
        border: 1px solid transparent;
    }
    &.small{
        padding: 0.8rem 0.8rem;
        font-size: 1rem;
        @include break-down(smallMobile){
            padding: 0.5rem 1.2rem;
            font-size: 1rem;
        }
        @include break-up(desktop){
            padding: 0.5rem 1.2rem;
            font-size: 1rem;
        }
    }
    &.squared{
        border-radius: var(--borderSmall);
    }
    &.primary{
        &:hover:not(:disabled){
            background-color: var(--white);
            color: var(--red);
            border: 1px solid var(--red);
        }
    }
    &.secondary{
        &:hover:not(:disabled){
            border: 1px solid var(--blue);
            background-color: var(--white);
            color: var(--blue);
        }
    }
    &.btnWhite{
        background-color: var(--white);
        color: var(--red);
        font-weight: bold;
        border: 1px solid var(--red);
        &:hover:not(:disabled){
            background-color: var(--red);
            color: var(--white);
            border: 1px solid var(--white);
        }
    }
    & svg{
        font-size: 1em;
    }
    &:disabled{
        background-color: var(--borderColor);
        color: var(--dark-grey);
        &:hover{
            background-color: var(--borderColor);
            color: var(--dark-grey);
        }
    }
}

.btn-favorite{
    position: absolute;
    top: 0.8rem;
    right: 0.8rem;
    display:flex;
    justify-content:center;
    align-items:center;
    color: var(--red);
    height: 2.5rem;
    min-height: 2.5rem;
    width: 2.5rem;
    min-width: 2.5rem;
    border: 1px solid var(--red);
    background-color: var(--white);
    border-radius: 100px;
    cursor: pointer;
    z-index: 1;
    &:hover{
        background-color: var(--red);
    }
    &:hover svg{
        transition: all 0.5 ease-in-out;
    }
    &:hover svg path{
        fill: var(--white);
        transition: all 0.5 ease-in-out;
    }
    & svg{
        height: 1.1rem;
    }
}

.form-button .btn{
    width: 100%;
}

.react-loading-skeleton.skeleton-card-btn{
    width: 8rem;
    height: 2.85rem;
    border-radius: 50px;
}
