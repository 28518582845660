@import "/src/app/styles/for-components";

.service-block{
    width: 65vw;
    max-width: 20rem;
    height: auto;
    margin: 0.5rem auto;
    padding: 2rem 0;
    display:flex;
    flex-flow: row wrap;
    justify-content:center;
    align-items:center;
    background-color: var(--white);
    border-radius: var(--borderSmall);
    box-shadow: var(--shadow);
    @include break-up(desktop){
        width: 90%;
        margin-bottom: 0;
    }
    @include break-up(big-desktop){
        width: 70%;
        & .service-carousel {
            width: 88%;
        }
    }

    & h4{
        width: 100%;
        text-align: center;
    }
}

.service-icone{
    width: 8rem;
    height:8rem ;
    margin-bottom: 2rem;
    display:flex;
    justify-content:center;
    align-items:center;
    border-radius: var(--borderMedium);
    @include break-up(desktop){
        width: 5rem;
        height: 5rem;

    }
    & svg{
        width: 40%;
        height: auto;
    }
}

.last{
    @include break-up(desktop){
        padding-bottom: 12rem;
    }
}

