@import "/src/app/styles/for-components";

.putOnSale{
    ul{
        padding-left: 1.2rem;
        li{
            list-style: disc;
            padding: 0.25rem 0;
            &::marker{
                color: var(--red);
            }
        }
    }
    .btn{
        width: fit-content;
        margin-top: 4rem;
    }
}

.section-form{
    margin-bottom: 4rem;
    .contact-form .input-block.subject{
        display: none;
    }
}