@import "/src/app/styles/for-components";

.share-icones{
    width: 80%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 3rem;
    @include break-up(desktop){
        width: min-content;
        gap: 1rem;
        margin: 3rem 0 0 0;
    }
    @include break-up(big-desktop){
        position: absolute;
        gap: 0;
        width: 30%;
        bottom: -5rem;
        margin-top: 0;
        right: 22%;
        width: 55%;
    }
}

.share-icone {
    display: flex;
    width: 3rem;
    height: 3rem;
    align-items: center;
    justify-content: center;
    border-radius: var(--borderSmall);
    color: var(--white);
    cursor: pointer;
    &:hover{
        box-shadow: rgb(60 64 67 / 30%) 0px 1px 2px 0px, rgb(60 64 67 / 15%) 0px 2px 6px 2px;
    }
    & svg{
        font-size: 1.5rem;
    }
}

.charge{
    height: 29px;
}
