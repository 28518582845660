@import "/src/app/styles/for-components";

.payment{
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: auto;
    margin-bottom: 5rem;
    @include break-up(desktop){
        flex-flow: row wrap;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 0;
    }
    &>div{
        width: 100%;
        margin: 2rem 0;
        @include break-up(desktop){
            width: 45%;
            margin: 0;
        }
        & p:last-child{
            font-size: 0.85rem;
        }
    }
    &>div:nth-child(1){
        &>div{
            width: 100%;
            display: flex;
            flex-flow: row wrap;
            padding: 1rem;
            border-radius: var(--borderSmall);
            cursor: pointer;
            &>div{
                width: 100%;
                display:flex;
                justify-content:flex-end;
                align-items:center;
                gap: 0.5rem;
            }
            &>div:first-child{
                font-weight: 500;

            }
            & p{
                flex: 1 1 auto;
            }
        }
    }
    &>div:nth-child(2){
        & div{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 0.5rem;
        }
        & span{
            font-weight: 500;
        }
    }
    & h5 {
        margin-bottom: 1rem;
        border-bottom: 1px solid var(--borderColor);
        padding-bottom: 0.5rem;
    }
    & button{
        width: 100%;
        margin: 1rem 0;
        @include break-up(desktop){
            width: 45%;
            margin: 2rem 0;
        }
    }
    & span.radio{
        width: 15px;
        min-width: 15px;
        height: 15px;
        border-radius: 50px;
        border: 1px solid var(--red);
    }
    & div.selected {
        background-color: var(--red);
        color: var(--white);
        & span.radio{
            width: 15px;
            min-width: 15px;
            height: 15px;
            border-radius: 50px;
            border: 4px solid var(--white);
        }
    }
}
