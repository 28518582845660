@import "/src/app/styles/for-components";

.section{
    width: 100%;
    margin: auto;
    padding: 3.125rem 0;
    @include break-up(desktop){
        width: 100%;
        padding: 6.25rem 0;
    }
    &.map{
        padding: 3.125rem 0 0 0;
    }
}
