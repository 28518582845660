@font-face {
  font-family: "SangBleuKingdom";
  font-style: normal;
  font-weight: normal;
  src: url("/src/app/assets/fonts/SangBleuKingdom-Regular.otf") format("opentype");
}

@font-face {
  font-family: "SangBleuKingdom";
  font-style: normal;
  font-weight: 500;
  src: url("/src/app/assets/fonts/SangBleuKingdom-Medium.otf") format("opentype");
}

@font-face {
  font-family: "SangBleuKingdom";
  font-style: normal;
  font-weight: 700;
  src: url("/src/app/assets/fonts/SangBleuKingdom-Bold.otf") format("opentype");
}
