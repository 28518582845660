@import "/src/app/styles/for-components";

.article-section{
    & h2{
        color: var(--red);
        margin: 1rem 0;
        font-size: 1.43em;
    }
}

/*HEADER*/

.article-header{
    height: 4.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    margin: auto;
    z-index: inherit;
    @include break-up(desktop){
        height: auto;
        flex-flow: row wrap;
        width: 95%;
        margin: auto;
        @include break-up(big-desktop){
            width: 80%;
        }
        &>div{
            width: 100%;
        }
        &>div:nth-child(1){
            margin: 2rem 0 1.5rem 0;
        }
    }
    & .article-header-h1{
        font-size: 2em;
        margin-top: 0.5rem ;
        color: var(--black);
        text-align: initial;
        @include break-up(desktop){
            display: block;
        }
    }
    & .price-span{
        display: none;
        @include break-up(desktop){
            display: flex;
            align-items: baseline;
            gap: 1rem;
            flex-direction: row;
            font-size: 1.5rem;
        }
    }
    & hgroup>span{
        display: none;
        @include break-up(desktop){
            display: block;
        }
    }
}

.article-header-chevronLeft{
    margin-right: 0.5rem;
}

.article-header-chevronRight{
    margin-left: 0.5rem;
}

.article-header-navigator{
    cursor: pointer;
    &:hover{
        color: var(--red);
    }
    &:active{
        color: var(--red);
        font-weight: 800;
    }
}

.article-header-info{
    display: flex;
    @include break-up(desktop){
        display: grid;
        grid: 100%/50% auto;
    }
}

.article-header-h1,
.article-header-price,
.article-header-info span{
    display: none;
    @include break-up(desktop){
        display: block;
    }
}

.article-header-nav{
    display: none;
    @include break-up(desktop){
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.article-header-icones{
    display: flex;
    gap: 0.5rem;
    @include break-up(desktop){
        justify-self: flex-end;
        margin-right: 1rem;
    }
    & .article-header-icone{
        height: 2.5rem;
        width: 2.5rem;
        display:flex;
        justify-content:center;
        align-items:center;
        border-radius: var(--borderSmall);
        border: 1px solid var(--red);
        color: var(--red);
        cursor: pointer;
        @include break-up(desktop){
            &:hover{
                background-color: var(--red);
                color: var(--white);
                box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
            }
        }
        &:active{
            background-color: var(--red);
            color: var(--white);
            box-shadow: none;
        }
    }
}

/*IMAGE*/

.article-image-carousel{
    width: 100%;
    @include break-up(desktop){
        & .splide__slide{
            display: flex;
            justify-content: center;
        }
    }
}

.article-image-carousel-block{
    position: relative;
    @include break-up(desktop){
        display: none;
    }
    & .article-image-carousel-close{
        display: none;
    }
    &.show{
        position: fixed;
        display: flex;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        display:flex;
        background-color: rgba(0, 0, 0, 0.7);
        z-index: 999;
        padding-top: calc((100vh - 40vw) / 2);
        .article-image-carousel-close{
            display: block;
            position: absolute;
            top: 1.5rem;
            right: 1.5rem;
            color: var(--white);
            cursor: pointer;
            @include break-up(desktop){
                top: 2.5rem;
                right: 2.5rem;
            }
        }
    }
    .splide__arrow svg{
        pointer-events: none;
    }
}

/*PRICE*/

.price-span{
    font-weight: 600;
    .strike{
        text-decoration: line-through;
        margin-right: 0.5rem;
    }
    .discount{
        color: var(--green);
    }
}

.period-span{
    display: block;
    color: var(--blue);
    @include break-up(desktop){
        font-size: 1.3rem;
    }
}

/*ARTICLE-FLOOR*/

.article-floorImage{
    width: 100%;
}

.article-floorImage-accordion{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    margin: 2rem 0;
    padding: 1rem;
    background-color: var(--light-grey);
    border-radius: var(--borderMedium);
    @include break-up(desktop){
        padding: 2rem 3rem;
        margin: 2rem 0 0 0;
    }
    & img{
        width: 100%;
        visibility: hidden;
        opacity: 0;
        height: 0px;
    }
    & svg{
        height: 1.5rem;
        cursor: pointer;
    }
}

/*ARTICLE IMAGE GRID*/

.article-image{
    position: relative;
}

.article-image-grid{
    position: relative;
    display: none;
    margin: auto;
    border-radius: var(--borderMedium);
    overflow: hidden;
    margin-top: 1rem;
    gap: 0.8em;
    @include break-up(desktop){
        width: 95%;
        display: grid;
        grid: repeat(2, clamp(150px, 16.75vw, 320px))/50% 25% 25%;
    }
    @include break-up(big-desktop){
           width: 80%;
    }
    & div{
        background-size: cover;
        background-position: center;
    }
    & div:nth-child(1){
        grid-row: 1/3;
        grid-column:1/2 ;
    }
    & div:nth-child(2){
        grid-row: 1/2;
        grid-column: 2/3;
    }
    & div:nth-child(3){
        grid-row: 1/2;
        grid-column: 3/4;
    }
    & div:nth-child(4){
        grid-row:2/3 ;
        grid-column:2/3 ;
    }
    & div:nth-child(5){
        grid-row:2/3 ;
        grid-column:3/4 ;
    }
}

.article-image-empty{
    position: relative;
    width: 100%;
    height: 250px;
    margin: auto;
    background-color: var(--dark-grey);
    margin-top: 1rem;
    display:flex;
    flex-flow: column wrap ;
    justify-content:center;
    align-items:center;
    gap: 3rem;
    color: var(--white);
    @include break-up(desktop){
        height: 33.5vw;
        width: 95%;
        border-radius: var(--borderMedium);
    }
    @include break-up(big-desktop){
        width: 80%;
    }
    & svg{
        height: 20%;
    }
}

.article-discount{
    position: absolute;
    top: 1rem;
    left: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    border-radius: var(--borderSmall);
    background-color: var(--green);
    color: var(--white);
    z-index: 1;
    cursor: pointer;
    @include break-up(desktop){
        left: 11%;
    }
    & svg{
        animation: Bounce 2s infinite ease-in-out;
    }
    & div{
        position: absolute;
        display: flex;
        width: max-content;
        left: 110%;
        padding: 0.5rem;
        background: var(--green);
        border-radius: var(--borderSmall);
        opacity:0;
        visibility: hidden;
    }
    &:hover div{
        opacity:1;
        visibility: visible;
    }
}

/*INFO*/

.article-info{
    position: relative;
    width: 90%;
    margin: auto;
    margin-top: 1rem;
    @include break-up(desktop){
        padding-bottom: 3.125rem;
        display: flex;
        justify-content: space-between;
        gap: 3rem;
        width: 95%;
        margin-top: 3rem;
    }
    @include break-up(big-desktop){
        width: 80%;
    }
}

.article-description{
    height: fit-content;
    @include break-up(desktop){
        position: sticky;
        top: 7rem;
        width: 65%;
    }
}

.article-info-title{
    display: block;
    color: var(--black);
    text-align: left;
    line-height: 1.2;
    font-size: 2rem;
    @include break-up(desktop){
        display: none;
    }
    & h1{
        margin: 1rem 0 0.5rem 0;

    }
}

.article-info .price-span{
    display: flex;
    align-items: baseline;
    gap: 0.5rem;
    flex-direction: column;
    font-size: 1.5rem;
    padding-bottom: 2rem;
    border-bottom: 1px solid var(--borderColor);
    @include break-up(desktop){
        display: none;
    }
}

/*sale*/

.article-sale-equipment{
    display: flex;
    flex-flow: row wrap;
    padding: 1rem 0px;
    background-color: var(--light-grey);
    border-radius: var(--borderMedium);
    margin: 2rem 0;
    @include break-up(desktop){
        padding: 1.5rem;
    }
    & ul{
        width: 100%;
        @include break-up(desktop){
            width: 100%;
        }
        @include break-up(big-screen){
            width: 50%;
        }
    }
    & li {
        display: grid;
        grid: 100%/48% 4% 48%;
        align-items: center;
        width: 95%;
        margin: auto;
        padding: 0.5rem 0px;
        border-bottom: 1px solid var(--borderColor);
        @include break-up(desktop){
            grid: 100%/36% 3% 61%;
        }
        & p, b{
            font-size: 0.888rem;
            justify-self: flex-start;
            @include break-up(desktop){
                font-size: 0.888rem;
            }
        }
    }
}

/*rent*/

.article-rent-equipment{
    margin: 2rem 0;
    & ul{
        display: flex;
        flex-flow: row wrap;
    }
    & li{
        width: 50%;
        display: flex;
        align-items: baseline;
        gap: 1rem;
        padding: 1rem 0px;
    }
}

.line-through{
    text-decoration: line-through;
}

.grid-btn{
    @include break-up(desktop){
        position: absolute;
        display:flex;
        justify-content:center;
        align-items:center;
        gap:0.5rem;
        bottom: 1rem;
        right: 1rem;
        padding: 0.5rem 1rem;
        background-color: var(--light-grey);
        border-radius: 100px;
        font-size: 0.777em;
        cursor: pointer;
        &:hover{
        background-color: var(--blue);
        color: var(--white);
        }
    }
}

.buttonBox{
    margin: 5.25rem auto;
}

@media only print {
    form,.section:has(.splide),footer,*[data-gloss]:before,*[data-gloss]:after,.splide__arrow{
        display: none;
    }
    .splide__track{
        aspect-ratio: 1.6 / 1;
        height: auto;
    }
}


