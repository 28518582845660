@import "/src/app/styles/for-components";

.empty-cart{
    display:flex;
    flex-flow: column wrap;
    justify-content:center;
    align-items:center;
    gap: 1rem;
    text-align: center;
    @include break-up(desktop){
        height: 100%;
        padding: 2rem;
        box-shadow: var(--shadow);
        border-radius: var(--borderMedium);
    }
    & button{
        margin: 2rem 0;
    }
}
