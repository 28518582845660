@import "/src/app/styles/for-components";

.twoColumn{
    display: flex;
    flex-flow: column wrap;
    @include break-up(desktop){
        width: 95%;
        margin: auto;
        flex-flow: row nowrap;
    }
    @include break-up(big-desktop){
        width: 80%;
        gap: 10%;
    }
    &.revertColumn{
        flex-direction: column-reverse;
        @include break-up(desktop){
            flex-direction: row-reverse;
        }
    }
}

.column{
    width: 90%;
    margin: auto;
    margin-bottom: 3rem;
    @include break-up(desktop){
        display: flex;
        justify-content: center;
        flex-flow: column;
        margin: 0;
        & .titleBox{
            width: 100%;
            margin: 0;
            margin-bottom: 2rem;
        }
        & .buttonBox{
            margin: 2.5rem 0;
        }
    }
}

.column-image{
    & img{
        width: 100%;
        height: 100%;
        border-radius: var(--borderBig);
        object-fit: cover;
        border-radius: 35px;
        @include break-up(desktop){
            border-radius: 70px;
        }
    }
    @include break-up(desktop){
        justify-content: center;
    }
}
