@import "/src/app/styles/for-components";

.cart-price{
    position: sticky;
    bottom: 0rem;
    width: 100%;
    padding: 2rem;
    box-shadow: var(--shadow);
    border-radius: var(--borderMedium);
    background-color: var(--white);
    z-index: 1;
    overflow: hidden;
    @include break-up(desktop){
        top: 6rem;
        width: 90%;
        align-self: flex-start;
        justify-self: flex-end;
        & svg{
            display: none;
        }
    }
    & h5{
        margin-bottom: 1rem;
        border-bottom: 1px solid var(--borderColor);
        padding-bottom: 0.5rem;
        display:flex;
        justify-content:space-between;
        @include break-up(desktop){
            display: block;
            justify-content: inherit;
        }
    }
    &>div{
        display:flex;
        flex-flow: row wrap;
        justify-content:space-between;
        align-items:center;
        margin-bottom: 0.5rem;
        span{
            font-weight: 500;
        }
    }
    div:has(.price){
        padding: 0.5rem 0;
        border-bottom: 1px dashed var(--borderColor);
    }
    .price{
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
    & button{
        margin: 2rem 0 0 auto;
    }
    .cart-price-total{
        p, span{
            font-weight: bold;
            font-size: 18px;
        }
    }
    .cart-price-account{
        color: var(--green);
        p, span{
            font-weight: bold;
            font-size: 18px;
        }
    }
}

.popUpRemove{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    background: var(--white);
    width: 90%;
    margin: auto;
    align-items: center;
    flex-flow: column wrap;
    padding: 2rem 1rem;
    box-shadow: var(--shadow);
    border-radius: var(--borderMedium);
    margin-bottom: 5rem;
    text-align: center;
    z-index: 15;
    @include break-up(desktop){
        width: 35%;
        padding: 2rem;
        margin-bottom: 0;
    }
    & h3{
        font-size: 22px;
        font-family: 'Open Sans', 'Helvetica Neue',
        sans-serif;
        }
    & .close-popUpRemove{
        position: absolute;
        right: 1rem;
        top: 1rem;
        cursor: pointer;
    }
    & .popUpRemove-buttons{
        width: 80%;
        margin: auto;
        gap: 2rem;
        justify-content: center;
        display: flex;
        margin-top: 2rem;
    }
}
