@import "/src/app/styles/for-components";

.articles{
    width: 90%;
    height: 100%;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(90%, auto));
    grid-auto-rows: minmax(100px, auto);
    gap: 10px;
    margin-bottom: 6.25rem;
    // @include break-down(smallMobile){
    //     grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    // }
    @include break-up(desktop){
        grid-template-columns: repeat(auto-fit, minmax(400px, auto));
        width: 100%;
        min-width: inherit;
        & .card{
            margin: 0px;
        }
    }
}

.articles-header{
    display: none;
    flex-flow: row wrap;
    margin: 0;
    width: 100%;
    padding: 1rem 5%;
    align-items: center;
    background-color: var(--white);
    @include break-up(desktop){
        top: 5.208rem;
        margin: 0;
        padding: 2rem 2.5% 1.7rem calc(2.5% + 5px);
        display: grid;
        grid-template-columns: auto minmax(auto, 450px) minmax(130px, 10%);
        position: sticky;
        top: 7.5rem;
        z-index: 2;
    }
    &.empty{
        height: auto;
        padding: 2rem;
        display: flex;
        .filter-name{
            display: none;
        }
        @include break-up(desktop){
            display: grid;
            grid-template-columns: auto minmax(auto, 450px) minmax(130px, 10%);
            margin-bottom: 10rem;
            .filter-name{
                display: block;
            }
        }
        & .articles-btnMap{
            position: static;
            margin: 1rem 0;
        }
    }
    h1{
        font-size: 1.111rem;
        color: var(--black);
        font-family: 'Open Sans','Helvetica Neue',sans-serif;
        text-align: left;
    }
    p {
        width: 100%;
        margin: 1rem 0;
    }
    .filters{
        display: none;
        gap: 1rem;
        padding: 1rem 0;
        @include break-up(desktop){
            display: flex;
        }
        label{
            display: flex;
            gap: 0.5rem;
        }
        input[type=checkbox]{
            border-color: var(--borderColor);
            &:checked{
                border-color: var(--red);
            }
        }
    }
    .filter-name{
        border-radius: var(--borderSmall);
        border: 1px solid var(--borderColor);
        background-color: var(--white);
        padding-left: 1rem;
        &:focus{
            border-color: #4671ff;
        }
        input{
            border: none;
        }
    }
}

.articles-container{
    min-height: 50vh;
    @include break-up(desktop){
        width: 95%;
        min-height: calc(100% - 2 * 5.208rem - 1rem);
        height: auto;
        margin: auto;
        padding: 5px;
        display: grid;
        grid-template-columns: 100% 0%;
        align-items: baseline;
        overflow-x: clip;
    }
}

.articles-btnMap{
    width: 100vw;
    position: fixed;
    bottom: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9;
    @include break-up(desktop){
        display: none;
    }
    & div {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 3rem;
        width: 40%;
        min-width: 10rem;
        padding: 0 0.5rem;
        gap: 0.5rem;
        background-color: var(--red);
        color: var(--white);
        &:active{
            background-color: var(--white);
            color: var(--red);
            & svg path{
                fill: var(--red);
            }
        }
    }
    & div:nth-child(1){
        border-bottom-left-radius: 50px;
        border-top-left-radius: 50px;
        box-shadow: rgb(14 21 45 / 23%) -5px 0px 10px;
    }
    & div:nth-child(2){
        border-bottom-right-radius: 50px;
        border-top-right-radius: 50px;
        box-shadow: rgb(14 21 45 / 23%) 5px 0px 10px;
 }
}

/*MAP*/

.articles-map{
    display: none;
    position: fixed;
    top: 4.5rem;
    width: 100%;
    height: calc(100vh - 4.5rem);
    z-index: 10;
    @include break-up(desktop){
        position: initial;
        height: calc(100vh - 2 * 5.208rem - 1rem);
        width: 100%;
        position: sticky;
        top: 10.416rem;
        align-self: start;
        padding-left: 2rem;
        z-index: 1;
    }
    &.showMap{
        display: block;
    }
}

.articles-map-button{
    position: absolute;
    top: 1rem;
    left: 1rem;
    z-index: 2;
    @include break-up(desktop){
        display: none;
    }
}

.ct-map__container.overloaded-map{
    height: 100%!important;
    @include break-up(desktop){
        border-radius: var(--borderMedium);
        overflow: hidden;
    }
}

.article-popUp-title{
    padding: 0.5rem;
    font-weight: bold;
}

.overloaded-map{
    & .leaflet-control-container{
        position: relative;
        top: 82%;
    }
    & .leaflet-right{
        position: relative;
        top: 82%;
    }
    & .leaflet-popup-content-wrapper{
        padding: 0;
        overflow: hidden;
        border-radius: var(--borderMedium);
    }
    & .leaflet-popup-content{
        margin: 0;
    }
    & .leaflet-container{
        & a{
            color: var(--black);
            &.leaflet-popup-close-button{
                background-color: rgba(176, 35, 24, 0.8);
                color: var(--white);
                border-radius: 50px;
                top: 10px;
                right: 10px;
                &:hover{
                    color: var(--red);
                    background-color: var(--white);
                }
            }
        }
    }
    & div.ct-map__defaultClusterInnerMarker{
        width: 55px;
        height: 55px;
        border-color: var(--red);
        background: var(--ctl-calendar-range-light-color);
        color: var(--white);
        transition: transform 0.25s ease-in-out;
        &:hover{
            transform: scale(1.2);
            transition: transform 0.25s ease-in-out;
        }
        &:before{
            height: calc(100% - 15px);
            width: calc(100% - 15px);
            border-color: var(--red);
        }
        &:after{
            height: calc(2 * var(--ctl-map-marker-size));
            width: calc(2 * var(--ctl-map-marker-size));
            background-color: var(--red);
        }
    }
}

/*POP UP*/

.article-popUp{
    width: 100%;
    height: auto;
    & .article-popUp-img{
        height: 13.25rem;
        width: auto;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        overflow: hidden;
    }
}

.toggle-map{
    display: none;
    align-items: center;
    gap: 0.5rem;
    font-weight: bold;
    @include break-up(desktop){
        display: flex;
        justify-self: flex-end;
    }
}

/*PAGINATION*/

.articles-pagination{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 6.25rem auto;
    @include break-up(desktop){
        margin-top: 1.25rem;
        margin-bottom: 12.5rem;
        gap: 1rem;
    }
}

.pagination-container{
    width: auto;
    display: flex;
    justify-content: space-between;
    align-items: end;
    gap: 2vw;
    @include break-up(desktop){
        gap: 1rem;
    }
}

.pagination{
    width: 10vw;
    height: 10vw;
    max-width: 2.666rem;
    max-height: 2.666rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--borderColor);
    border-radius: var(--borderSmall);
    cursor: pointer;
    &:hover{
        color: var(--red);
        box-shadow: var(--shadow);
        border-color: var(--red);
    }
    &:active{
        color: var(--white);
        background-color: var(--red);
        border-color: var(--red);
    }
    &.currentPage{
        color: var(--white);
        background-color: var(--red);
        border-color: var(--red);
    }
}
