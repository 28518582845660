@import "/src/app/styles/for-components";

.searchModule {
    width: 100%;
    max-width: 1920px;
    box-shadow: var(--shadow);
    z-index: 9;
    @include break-up(desktop){
        display: block;
        position: sticky;
        top: 0;
    }
    &.down{
        position: absolute;
        top: 65svh;
        left: 0;
        z-index: 2;
        @include break-up(desktop){
            width: 90%;
            position: absolute;
            top: 75svh;
            left: 50%;
            transform: translateX(-50%);
            max-width: 70rem;
            min-width: 57rem;
            border-radius: var(--borderSmall);
            background-color: var(--light-grey);
            & .searchModule-container{
                width: 100%;
                background-color: var(--white);
            }

        }
        @include break-up(big-desktop){
            min-width: 59rem;
        }
    }
    &.up{
        position: sticky;
        top: 4.5rem;
        margin: auto;
        width: 100%;
        background-color: var(--blue);
        @include break-up(desktop){
            transform: translateX(0);
            width: 100%;
            margin: auto;
            top: 40px;
            background-color: var(--light-grey);
            gap: 0.5rem;
            & .searchModule-container{
                max-width: 67rem;
                min-width: 57rem;
            }
        }
    }
    .ct-calendar__controllers__calendarDay.cds-default.cds-valid .ct-calendar__controllers__calendarDayVal{
        border: 1px solid var(--green);
    }
}

.witness{
    position: absolute;
    top: 0;
    left:0;
    visibility: hidden;
    opacity: 0;
}

.searchModule-container .searchTypeButtons,.searchModule-container .searchModule-button{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 9rem;
    cursor: pointer;
    font-size: 1rem;
    @include break-up(desktop){
        font-size: inherit;
        min-width: 9%;
        white-space: nowrap;
    }
}

.searchModule-container{
    width: 100%;
    height: auto;
    padding: 1rem 0;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: center;
    @include break-up(desktop){
        width: 98%;
        height: 5.208rem;
        margin: auto;
        flex-flow: row nowrap;
        gap: 0;
        padding: 0 0.5rem;
        border-radius: var(--borderSmall);
        &>div:not(.searchTypeButtons){
            height: 60%;
            width: auto;
            border-radius: var(--borderSmall);
            padding: 1rem;
            gap: 0.5rem;
        }
    }
    @include break-up(big-desktop){
        padding: 0 1rem;
    }
    &>div:not(.searchTypeButtons):hover .subInput{
        opacity: 1;
        visibility: visible;
        transition: all 0.5s ease-in-out;
    }
}

.searchModule-arrow{
    position: relative;
    transform: translateY(50%);
    transition: all 0.5s ease-in-out;
    @include break-up(desktop){
        width: 0.7rem;
        &:hover{
            transform: rotate(180deg);
            transition: all 0.5s ease-in-out;
        }
    }
}

.searchModule-button{
    border:1px solid var(--borderColor);
    background-color: var(--white);
    &.primary{
        border-color:var(--red);
        background-color: var(--red);
        &:active{
            background-color: var(--red);
            color:var(--white);
            fill:var(--white)
        }
    }
    &.persons{
        min-width: 155px;
    }
    @include break-up(desktop){
        &:hover .searchModule-arrow{
            transform: rotate(180deg);
            transition: all 0.5s ease-in-out;
            border-color: var(--red);
        }
        &.primary:hover{
            background-color: var(--white);
            border:1px solid var(--red);
            color: var(--black);
            fill: var(--black);
        }
    }
}

/*Search button*/

.searchbutton{
    height: auto;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    border: none;
    border-radius: var(--borderSmall);
    padding: 1rem;
    margin: auto;
    margin-top: 2rem;
    cursor: pointer;
    @include break-up(desktop){
        height: 60%;
        border: 1px solid var(--blue);
        margin: 0;
        &:hover{
            background-color: var(--white);
            color: var(--black);
            border: 1px solid var(--blue);
            & path{
                stroke: var(--blue);
            }
        }
    }
    &.primary:active{
        background-color: var(--white);
        color: var(--red);
        stroke: var(--red);
    }
    &.smartphone{
        text-transform: uppercase;
        letter-spacing: 1px;
    }
}

/*Search type*/

.searchTypeButtons{
    height: auto;
    width: 95%;
    @include break-up(desktop){
        height: 60%;
        width: auto;
    }
}

#location,#sale,.dates-mobile,.nbrPerson-mobile{
    padding: inherit;
    height: 3.5em;
    width: 50%;
}

#location{
    border-top-left-radius: var(--borderSmall);
    border-bottom-left-radius: var(--borderSmall);
    gap: 0.5rem;
    &.cornerTopLeft{
        border-bottom-left-radius: inherit;
    }
    &  svg{
        position: relative;
        transform: translateY(50%);
    }
    @include break-up(desktop){
        padding: inherit;
        height: 100%;
        width: 13rem;
        &:hover .searchModule-arrow{
            transform: rotate(180deg);
            transition: all 0.5s ease-in-out;
        }
        &:hover .subInput{
            opacity: 1;
            visibility: visible;
            transition: all 0.5s ease-in-out;
        }
    }
}

#sale{
    border-top-right-radius: var(--borderSmall);
    border-bottom-right-radius: var(--borderSmall);
    @include break-up(desktop){
        padding: inherit;
        height: 100%;
        width: 9vw;
        max-width: 9.74rem;
    }
    &.cornerTopRight{
        border-bottom-right-radius: inherit;
    }

}

.dates-mobile{
    border-bottom-left-radius: var(--borderSmall);
}

.nbrPerson-mobile{
    border-bottom-right-radius: var(--borderSmall);
}

.dates-mobile,.nbrPerson-mobile{
    gap: 0.5rem;
    &:active{
        background-color: var(--red);
        color: var(--white);
    }
    @include break-up(desktop){
        display: none;
    }
}

/*MOBILE SPEC*/

.searchTypeChoice-mobile{
    top: 100%;
    position: absolute;
    left: 0;
    width: 200%;
    box-shadow: var(--shadow);
    border-bottom-right-radius: var(--borderSmall);
    border-bottom-left-radius: var(--borderSmall);
    background-color: var(--white);
    padding: 1rem;
    display: flex;
    flex-flow: row wrap;
    gap: 1rem;
    z-index: 999;
    & p{
        width: auto;
        white-space: nowrap;
        display: flex;
        align-items: center;
        gap: 1rem;
        cursor: pointer;
    }
}

.filterButton{
    /* display:flex; */
    display:none;
    background-color: var(--light-red);
    width: 100px;
    border-radius: 150px;
    text-align: center;
    color: var(--red);
    justify-content:space-between;
    align-items:center;
    margin-left: 5%;
    padding: 0.5rem 1rem;
    &:active{
        background-color: var(--red);
        color: var(--white);
    }
}

.searchTypeChoice-mobile label{
    width: 100%;
}

.filterInputs{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100svh;
    padding-top: 4.5rem;
    background-color: white;
    z-index: 998;
    overflow-y: scroll;
    overflow-x: clip;
    contain: paint;
    &::-webkit-scrollbar{
        width: 0px;
    }
    &>button.primary{
        margin: auto;
    }
    .ct-calendar__controllers__calendarDay.cds-default.cds-valid .ct-calendar__controllers__calendarDayVal{
        border: 1px solid var(--green);
    }
    .filters{
        display: flex;
        gap: 1rem;
        label{
            display: flex;
            gap: 0.5rem;
        }
        input[type=checkbox]{
            border-color: var(--borderColor);
            &:checked{
                border-color: var(--red);
            }
        }
    }
    .filter-name{
        >div{
            border: 1px solid var(--borderColor);
            border-radius: var(--borderSmall);
            padding-left: 0.5rem;
        }
        &:focus{
            border-color: #4671ff;
        }
        input{
            border: none;
        }
    }
}

.filterInputs-header{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 4.5rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 5%;
    background-color: white;
    box-shadow: var(--shadow);
    z-index: 999;
    & h4{
        position:absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-56%);

    }
    & svg{
        height: 1.5rem;
    }
}

.searchModuleInputs{
    width: 90%;
    margin: 5svh auto;
    & h5{
        margin-bottom: 1rem;
        span{
            display: block;
            font-style: italic;
        }
    }
    & .ct-calendar__controllers__calendarWrapper{
        margin-top: 0;
    }
}


.searchModuleInputs-block{
    display: flex;
    margin-top: 1rem;
    gap: 3vw;
    & label{
        display: flex;
        align-items: center;
        font-size: 1.142rem;
        gap: 1rem;
    }
}

.searchTypeChoice-mobile{
    & label{
        display: flex;
        align-items: center;
        font-size: 1.142rem;
        gap: 1rem;

    }
}

.searchModuleInputs{
    & .bookingBox-inputDate{
        display: none;
        @include break-up(desktop){
            position: relative;
            display: flex;
            justify-content: space-between;
            margin-top: 2rem;
        }
    }
    & .bookingBox-inputDate .input-block{
        width: 50%;
        & input{
            width: 100%;
        }
    }

}

#arrival{
    border-bottom-right-radius:0 ;
    border-top-right-radius: 0;
}

#departure{
    border-bottom-left-radius:0 ;
    border-top-left-radius: 0;
}

.filterInputs-footer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem;
    align-items: center;
    text-align: center;
    width: 100%;
    background-color: var(--white);
    border-bottom: 1px solid var(--borderColor);
    padding: 1rem 5%;
    & p{
        width: 100%;
        display: none;
        @include break-up(desktop){
            display: block;
        }
    }
    @include break-up(desktop){
        display: none;
    }
}

.searchModule-container.browser{
    display: none;
    @include break-up(desktop){
        display: flex;
    }
}

.searchModule-container.smartphone{
    display: none;
    visibility: hidden;
    &.isVisible{
        display: flex;
        visibility: visible;
        @include break-up(desktop){
            display: none;
            visibility: hidden;
        }
    }
}

.searchbutton.smartphone{
    @include break-up(desktop){
        display: none;
    }
}
