@import "/src/app/styles/for-components";


.cart{
    width: 95%;
    margin: auto;
    @include break-up(desktop){
        width: 95%;
        margin-bottom: 10rem;
    }
    @include break-up(big-desktop){
        width: 90%;
    }
}

.cart-content{
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto 9%;
    margin-bottom: 5rem;
    @include break-up(desktop){
        grid-template-columns: 60% 40%;
        grid-template-rows: auto;
        margin-bottom: 0;
    }

}

