@import "/src/app/styles/for-components";

.banner{
    position: relative;
    width: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    contain: paint;
    @include break-up(desktop){
        width: 95%;
        border-radius: var(--borderBig);
        margin-bottom: 2.25rem;
    }
    @include break-up(big-desktop){
        width: 90%;
    }

    &.full-banner{
        height: 100svh;
        @include break-up(desktop){
            height: 90vh;
        }
    }

    &.small-banner{
        height: 15rem;
        @include break-up(desktop){
            height: 24rem;
            margin-top: 2rem;
        }
    }

    & h1{
        display: flex;
        flex-flow: column wrap;
        z-index: 1;
        @include break-up(desktop){
            transform: inherit;
        }
        &:not(.small-banner h1){
            transform: translateY(-8rem);
            @include break-up(desktop){
                transform: translateY(-3rem);
            }
        }
    }

    & span{
        font-family: SangBleuKingdom,'Arial', 'sans-serif';
        font-size: 2.777rem;
    }
}


.blackVeil{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.4);
    z-index: 0;
}
