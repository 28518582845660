@import "/src/app/styles/for-components";

.fourColumn{
    width: 90%;
    margin: auto;
    display: flex;
    flex-flow: column wrap;
    text-align: center;
    @include break-up(desktop){
        display: grid;
        grid:repeat(2,auto)/repeat(2,50%)
    }
    @include break-up(big-desktop){
        display: grid;
        grid:repeat(2,auto)/repeat(4,25%)
    }
}
