@import "/src/app/styles/for-components";

.form-result{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    background: var(--white);
    width: 90%;
    margin: auto;
    display: flex;
    align-items: center;
    flex-flow: column wrap;
    padding: 2rem 1rem;
    box-shadow: var(--shadow);
    border-radius: var(--borderMedium);
    margin-bottom: 5rem;
    text-align: center;
    z-index: 15;
    @include break-up(desktop){
        width: 65%;
        padding: 2rem;
        margin-bottom: 0;
    }
    @include break-up(big-desktop){
        width: 35%;
    }
    & h3{
        font-size: 22px;
        font-family: 'Open Sans', 'Helvetica Neue',
        sans-serif;
        }
    &>div:first-child{
        display:flex;
        justify-content:center;
        align-items:center;
        width: 80px;
        height: 80px;
        border-radius: 200px;
        border: 4px solid var(--red);
        color: var(--red);
        margin-bottom: 2rem;
        &.succeeded{
            border: 4px solid var(--green);
            color: var(--green);
        }
        & svg{
            height: 70%;
        }

    }
    & p{
        margin-top: 2rem;
    }
    & button{
        margin-top: 2rem;
    }
    & .close-form-callback{
        position: absolute;
        right: 1rem;
        top: 1rem;
        cursor: pointer;
    }
    & .form-result-buttons{
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 80%;
        @include break-up(desktop){
            gap: 2rem;
            flex-direction: row;
        }
    }
}
