@import "/src/app/styles/for-components";

.content{
    width: 90%;
    margin: auto;
    display: flex;
    flex-direction: column;
    @include break-up(desktop){
        width: 80%;
    }
    li{
        max-width: 85ch;
        line-height: 1.7;    
    }
    p:nth-of-type(1){
        margin-bottom: 0.5rem;
    }
    .step{
        width: fit-content;
        border-radius: var(--borderBig);
        box-shadow: var(--shadow);
        margin: 1rem 0 2rem 0;
        contain: paint;
        overflow: hidden;
        @include break-up(desktop){
        }
        .nbr{
            padding: 1rem 2rem;
            font-size: 32px;
            background-color: var(--red);
            color: var(--white);
            @include break-up(desktop){
                height: 100%;
            }
        }
        div:not(.nbr){
            padding: 2rem;
        }
        h4{
            margin-bottom: 1rem;
        }
    }
    ul{
        padding-left: 1.2rem;
        li{
            list-style: disc;
            padding: 0.25rem 0;
            &::marker{
                color: var(--red);
            }
        }
    }
    h3{
        margin-top: 2rem;
        color: var(--red);
    }
    .step:nth-of-type(1){
        margin-top: 6rem;
    }
    .step:nth-of-type(2){
        .nbr{
            background-color: var(--blue);
        }
    }
    form{
        margin-top: 2rem;
    }
}
.contact{
    padding-bottom: 12rem;
}

.goodToKnow, .contact{
    p{
        margin: auto;
        text-align: center;
        @include break-up(desktop){
            max-width: 80%;
        }
    }
}