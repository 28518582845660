@import "/src/app/styles/for-components";

.menu{
    position: relative;
    width: 100%;
    height: 4.5rem;
    @include break-up(desktop){
        height: auto;
        padding: 1rem 0;
        margin-top: 40px;
        z-index: 10;
    }
}

.burger{
    z-index: 999;
    cursor: pointer;
    @include break-up(desktop){
        display: none;
    }
    & svg{
        transform: scale(0.9);
    }
}

/*___NAVIGATION BANDEROLE ICONES___*/

.navigation-banderole{
    display: none;
    @include break-up(desktop){
        position: fixed;
        top:0;
        width: 100%;
        max-width: 1920px;
        height: 40px;
        display: block;
        z-index: 11;
        &>div:nth-child(1){
            width: 95%;
            height: 100%;
            margin: auto;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
    & .banderole-contact{
        display: flex;
        gap: 1rem;
        width: auto;
        justify-content: space-between;
        & a{
            display:flex;
            align-items:center;
            font-size: 14px;
        }
        & svg{
            margin: 0px 0.521rem;
            position: relative;
            transform: translateY(0.08rem);
            width: 13px;
        }
    }
    & .banderole-icones{
        width: 9rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &>div{
            display: flex;
            align-items: center;
            cursor: pointer;
            position: relative;
        }
    }
}

.smartphone-menuIcones{
    position: absolute;
    top: 0;
    left:50%;
    transform: translateX(-30%);
    display: flex;
    align-items: center;
    gap: 1.5rem;
    z-index: 999;
    & .current-language{
        padding: 0;
    }
}

.icone{
    display: block;
    width: 1.4rem;
    @include break-up(desktop){
        width: 0.883rem;
        transition: all 0.5 ease-in-out;
        &:hover{
            transform: scale(1.2);
            transition: all 0.5 ease-in-out;
        }
    }
}

.weather{
    width: 2rem;
    @include break-up(desktop){
        fill: var(--white);
        width: 1.423rem;
    }
}

svg.basket{
    height: 1.6rem;
    width: 1.6rem;
    @include break-up(desktop){
        height: 1.2rem;
        width: 1.2rem;
    }
}

svg.user{
    width: 1.4rem;
    height: 1.4rem;
    @include break-up(desktop){
        width: 1.1rem;
        height: 1.1rem;
    }
}

.profil{
    position: relative;
    &.isLogged:after{
        content: "";
        position: absolute;
        width: 8px;
        height: 8px;
        background-color: var(--green);
        border-radius: 50px;
        top: -3px;
        right: 1px;
    }
    & .userMenu{
        width: auto;
        padding: 1rem 0;
        top: 2rem;
        position: absolute;
        left: 50%;
        transform: translateY(20%) translateX(-50%);
        text-align: center;
        z-index: 999;
        border-bottom-right-radius: var(--borderSmall);
        border-bottom-left-radius: var(--borderSmall);
        @include break-up(desktop){
            top: 0;
        }
        & li a {
            display: block;
            white-space: nowrap;
            padding: 0.5rem 1rem;
            &:hover{
                background: var(--white);
                color: var(--black);
            }
        }
    }
}

.cartQuantity{
    position: absolute;
    top: -0.5rem;
    right: -1.3rem;
    display:flex;
    justify-content:center;
    align-items:center;
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 5px;
    background-color: var(--white);
    color: var(--red);
    font-weight: 500;
}


/*___NAVIGATION___*/

nav{
    width: 100%;
    height: 100%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    @include break-up(desktop){
        width: 95%;
    }
    @include break-up(big-desktop){
        width: 80%;
        height: 75%;
        justify-content: space-between;
    }
}

.navigation-logo{
    width: auto;
    display: flex;
    align-items: center;
    margin-left: 1rem;
    @include break-up(desktop){
        margin: 0;
    };
    & svg{
        width: 11rem;
        @include break-up(desktop){
            width: 15rem;
        }
    }
}

.navigation{
    position: absolute;
    left: 0;
    top: 0;
    height: auto;
    min-height: 100vh;
    width: 100%;
    flex-flow: column nowrap;
    align-items: center;
    display: flex;
    opacity: 0;
    visibility: hidden;
    z-index: 998;
    background-color: var(--red);
    gap: 4vh;
    &.isHome{
        background-color: var(--white);
    }
    @include break-up(desktop){
        background-color: transparent;
        position: relative;
        min-height: inherit;
        width: auto;
        gap: 0.5%;
        flex-flow: row nowrap;
        opacity: 1;
        visibility: visible;
        &.isHome{
            background-color: transparent;
        }
    }
    & .navigation-link:nth-child(1){
        margin-top: 4.5rem;
        @include break-up(desktop){
            margin-top: 0;
        }
    }
}

.current-language{
    position: relative;
    height: auto;
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    cursor: pointer;
    padding-left: 2rem;
    font-size: 1.5rem;/*21px*/
    color: var(--white);
    letter-spacing: 2px;
    @include break-up(desktop){
        position: relative;
        text-transform: uppercase;
        padding: 0;
        &:hover .navigation-arrow{
            transform: rotate(180deg);
            transition: transform 0.5s ease-in-out;
        }
    }
    & span{
        width: auto;
        border-radius: var(--borderMedium);
        border: 1px solid transparent;
        transition: border 0.25s ease-in-out;
        font-size: 1.2rem;
        font-weight: 600;
        letter-spacing: 2px;
        display: flex;
        align-items: center;
        color: var(--white);
        @include break-up(desktop){
            font-size: 1rem;
        }
    }
    & svg{
        fill: var(--white);
    }
}

.navigation-link{
    position: relative;
    height: auto;
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    cursor: pointer;
    padding-left: 2rem;
    font-size: 1.5rem;/*21px*/
    @include break-up(desktop){
        height: 100%;
        justify-content: center;
        padding: inherit;
        text-transform: uppercase;
        &:hover>span,&:hover>a{
            border-color: var(--white);
            transition: all 0.25s ease-in-out;
        }
        &:hover>span.whiteBorder,&:hover>a.whiteBorder{
            border-color: var(--blue);
            transition: all 0.25s ease-in-out;
        }
        &:hover .navigation-arrow{
            transform: rotate(180deg);
            transition: transform 0.5s ease-in-out;
        }
    }
    &>span,&>a{
        width: auto;
        border-radius: var(--borderMedium);
        border: 1px solid transparent;
        transition: border 0.25s ease-in-out;
        font-size: 1.5rem;/*21px*/
        font-weight: 600;
        letter-spacing: 1.5px;
        display: flex;
        align-items: center;
        @include break-up(desktop){
            justify-content: center;
            padding: 0.520rem 0.9rem;
            text-align: center;
            font-size: 16px;
            letter-spacing: 2px;
        }
    }
}

.navigation-arrow{
    width: 0.8rem;
    position: relative;
    top: 50%;
    margin-left:0.520em ;
    transition: transform 0.5s ease-in-out;
    @include break-up(desktop){
        width: 0.561rem;
    }
}

.magnifying-glass,.current-language span,.navigation-link>span,.navigation-link>a{
    min-height: 5rem;
    font-weight: 400;
    text-transform: uppercase;
    @include break-up(desktop){
        min-height: inherit;
    }
}

.magnifying-glass{
    min-width: 5rem;
    & svg{
        width: 1.5rem;
        @include break-up(desktop){
            width: 1rem;
        }
    }

}

/*___SUBMENU___*/

.submenu{
    width: 100%;
    min-width: 9rem;
    display: flex;
    flex-flow: column wrap;
    display: flex;
    position: relative;
    top: 100%;
    left: 0%;
    border-radius: 0 0 var(--borderSmall) var(--borderSmall);
    transition: all 0.5s ease-in-out;
    overflow: hidden;
    z-index: 999;
    @include break-up(desktop){
        width: auto;
        position: absolute;
        box-shadow: var(--shadow);
        white-space: nowrap;
    }
    & li{
        flex: 0 0 20%;
        position: relative;
        text-transform: uppercase;
        font-size: 1rem;
        letter-spacing: 1px;
         @include break-up(desktop){
            font-size: 0.729rem;
            &:hover{
                background-color: var(--red);
                color:var(--white);
            }
            &.whiteHover:hover{
                background-color: var(--white);
                color:var(--red);
            }
         }
    }
    & li a{
        display: block;
        padding: 1rem;
        padding-left: 0;
        font-size: 1.2rem;
        letter-spacing: 2px;
        @include break-up(desktop){
            padding-left: 0.82rem;
            font-size: 1rem;
        }
    }
    &.white{
        border-top: 1px solid var(--red);
    }
    &.primary{
        border-top: 1px solid var(--white);
    }

}

/*___LANGUAGE LIST___*/

.language a{
    display: block;
    width: 100%;
    padding: 1rem;
    padding-left: 0.82rem;
    text-transform: uppercase;
    @include break-up(desktop){
        padding: 0.521rem;
        &:hover{
            background-color: var(--white);
            color: var(--blue);
        }
    }

}

.language-list{
    width: 100%;
    position: absolute;
    top: 60%;
    left: 0;
    transform: translateX(-0.521rem);
    padding: 0.521rem 0;
    transition: all 0.5s ease-in-out;
    z-index: 999;
    margin-top: 0.5rem;
    @include break-up(desktop){
        width: 140%;
        top: 0%;
        top: 100%;
        margin-top: 0;
    }
}

